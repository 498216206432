'use client';

import { useScroll } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
// layouts
import MainLayout from 'src/layouts/main';
// components
import ScrollProgress from 'src/components/scroll-progress';
//
import HomeHero from '../home-hero';
import HomeMinimal from '../home-minimal';
import HomePricing from '../home-pricing';
import HomeDarkMode from '../home-dark-mode';
import HomeLookingFor from '../home-looking-for';
import HomeForDesigner from '../home-for-designer';
import HomeColorPresets from '../home-color-presets';
import HomeAdvertisement from '../home-advertisement';
import HomeCleanInterfaces from '../home-clean-interfaces';
import HomeHugePackElements from '../home-hugepack-elements';
import { _pricingPlans } from 'src/_mock';
import PricingCard from 'src/sections/pricing/pricing-card';
import { CTA } from 'src/components/cta';
import PricingView from 'src/sections/pricing/view';
import Iconify from 'src/components/iconify';


import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Button as MuiButton } from '@mui/material';
import Image from 'next/image';
import Grid from '@mui/material/Unstable_Grid2';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import NextLink from 'next/link'
import Link from '@mui/material/Link';

import Video from 'next-video';
import myMobileVideo from '/videos/hero_mobile.mp4';
import myVideo from '/videos/hero.mp4';

import { useInView } from 'react-intersection-observer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { is } from 'immer/dist/internal';



// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

export default function HomeView() {
  const { scrollYProgress } = useScroll();

  const { ref, inView, entry } = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <MainLayout>
      <ScrollProgress scrollYProgress={scrollYProgress} color="brand" />

      <Container sx={{ my: {xs:10, md:20}, p:2 }}>
        <Box>
          <Typography variant="h1" sx={{ mb: 2 }}>
            Revolutionize Your Amazon Sourcing Strategy
          </Typography>
          <Typography variant="body1" sx={{ mb: 3, maxWidth: '40rem' }}>
            Discover new products as soon as they're listed, and gain a competitive edge in your Amazon selling strategy.
          </Typography>
          <Stack direction="row" spacing={2}>
            <MuiButton variant="contained" color="brand">              
              <Link href="#pricing" component={NextLink} variant="body2" sx={{color:'#ffffff'}}>
              Start 7-Day Free Trial
              </Link>
            </MuiButton>
            {/* <MuiButton variant="outlined" color="brand">
              Learn More
            </MuiButton> */}
          </Stack>
        </Box>

        <Box sx={{ my: {xs:10, md: 20}, mx:{xs:5, lg:0}, boxShadow: '0 0 1px #fff, 0 0 5px #fff, 0 0 15px #fff, 0 0 4px #6d78b2, 0 0 26px #6d78b2, 0 0 39px #6d78b2, 0 0 56px #6d78b2, 0 0 1px #6d78b2'}}>
         {isSmall && <Video src={myMobileVideo}/>}
         {!isSmall && <Video src={myVideo}/>}
        </Box>

        <Box sx={{ my: {xs:15, md:20} }}>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid xs={12} md={6} sx={{pb:4}}>              
              <Typography variant="h3" sx={{ mb: 2 }}>
                Storefront Stalking will completely change your Amazon strategy.
              </Typography>
              <Typography variant="body1">
                Discover the power of strategic sourcing with Storefront Stalking, a cutting-edge approach to finding lucrative product opportunities on Amazon. This method is more than just a tool – it's a transformative strategy that allows you to systematically monitor and analyze the product listings of successful Amazon storefronts.
              </Typography>
              <Chip label="A Proven Strategy" variant="outlined"  sx={{ mt:2, mr:1 }}/>
              <Chip label="For New Or Experienced" variant="outlined"  sx={{ mt:2, mr:1 }}/>
            </Grid>
            <Grid xs={12} md={6}>
                <Stack direction="row">
                  <Stack direction="column" sx={{ py: 3, px:3, borderRight:1, borderColor:'#555555'}}>
                    <Typography variant="h2" align="center" ref={ref}>
                      2,275+
                    </Typography>
                    <Typography variant="h4" align="center">
                      Storefronts Tracked
                    </Typography>
                  </Stack>
              
                  <Stack direction="column" sx={{ py: 3, px:3 }}>
                    <Typography variant="h2" align="center">
                    450,000+
                    </Typography>
                    <Typography variant="h4" align="center">
                      ASINS Found
                    </Typography>
                  </Stack>
                </Stack>
            </Grid>
          </Grid>
        </Box>


        <Box className="h-neonBox" sx={{my: {xs:15, md:20}, mx:{xs:5, lg:0}, border:1, borderRadius:0, padding:3, boxShadow: '0 0 1px #fff, 0 0 5px #fff, 0 0 15px #fff, 0 0 4px #6d78b2, 0 0 26px #6d78b2, 0 0 39px #6d78b2, 0 0 56px #6d78b2, 0 0 1px #6d78b2'}}>
        <Typography variant="h3">
          Limited Time Offer
        </Typography>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Try Storefront Stalker Pro free for 7 days
          </Typography>
        <Typography variant="body1" sx={{ mb: 3, maxWidth: '40rem' }}>
          Discover new products as soon as they're listed, and gain a competitive edge in your Amazon selling strategy.
        </Typography>
        <Stack direction="row" spacing={2}>
          <MuiButton variant="contained" color="brand">
          <Link href="#pricing" component={NextLink} variant="body2" sx={{color:'#ffffff'}}>
              Start 7-Day Free Trial
              </Link>
          </MuiButton>
        </Stack>
      </Box>


        <Box sx={{my: {xs:15, md:20} }}>
          <Typography variant="h3" sx={{ mb: 5 }} align="center">
            Elevate Your Sourcing Game
          </Typography>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h4">
                Automated Tracking
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid xs={12} md={6}>
                  <Typography variant="body1">
                    Storefront Stalking automates the process of keeping tabs on selected Amazon sellers. This means you get instant updates when these sellers add new products, giving you insights into trending items and potential market gaps.
                  </Typography>
                  <Chip label="Automatically Track New ASINS" sx={{ mt:2, mr:1 }}/>
                  <Chip label="Track Any Storefront" sx={{ mt:2 }}/>
                </Grid>
                <Grid xs={12} md={6}>
                <img src="/assets/images/home/Stores.png" alt="Storefront Stalker Pro Stores List" style={{padding:'1rem',backgroundColor:'#5d5d5d',borderRadius:'4px'}}/>

                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography variant="h4">
                Data-Rich Insights
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid xs={12} md={6}>                  
                  <Typography variant="body1">
                  With each new product listing, you receive comprehensive data – from pricing history to sales velocity – enabling you to make informed decisions about what products to source and sell.
                  </Typography>
                  <Chip label="Quickly See Important Data" sx={{ mt:2, mr:1 }}/>
                  <Chip label="Make Decisions In Seconds" sx={{ mt:2 }}/>
                </Grid>
                <Grid xs={12} md={6}>
                <img src="/assets/images/home/Data.png" alt="Storefront Stalker Pro Store Data" style={{padding:'1rem',backgroundColor:'#5d5d5d',borderRadius:'4px'}}/>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>


          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <Typography variant="h4">
                Competitive Edge
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid xs={12} md={6}>                
                  <Typography variant="body1">
                  By understanding what successful sellers are doing, you can adapt your own storefront to mirror successful trends, helping you stay competitive and relevant in a constantly evolving marketplace.
                  </Typography>
                </Grid>
                <Grid xs={12} md={6}>
                <img src="/assets/images/home/Hero.png" alt="Storefront Stalker Pro filters" style={{padding:'1rem',backgroundColor:'#5d5d5d',borderRadius:'4px'}}/>

                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>


          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4-content"
              id="panel4-header"
            >
              <Typography variant="h4">
                Time-Saving Efficiency
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} justifyContent="center">
                <Grid xs={12} md={6}>                
                  <Typography variant="body1">
                  This method eliminates the guesswork and hours spent manually searching through Amazon. Our tool streamlines this process, freeing up your time to focus on other aspects of your business.
                  </Typography>
                  <Chip label="Advanced Filtering" sx={{ mt:2, mr:1 }}/>
                  <Chip label="Find Items Quickly" sx={{ mt:2 }}/>
                </Grid>
                <Grid xs={12} md={6} sx={{position:'relative', width:1}}>
                  <img src="/assets/images/home/Filters.png" alt="Storefront Stalker Pro filters" style={{padding:'1rem',backgroundColor:'#5d5d5d',borderRadius:'4px'}}/>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

        </Box>

        <Box sx={{my: {xs:15, md:20}}}>
          <Stack direction={{ sm: 'column', md:'row' }} spacing={{ xs: 3, md: 5 }} useFlexGap>
            <Card sx={{ borderRadius:0 }}>
              <CardContent>
                <Stack direction="row" spacing={2} sx={{mb:2}}>
                  <Box sx={{width:30,height:30, background:'linear-gradient(135deg, rgb(229 229 229 / 37%) 0%, rgb(163 163 163 / 26%) 50%, rgba(0,0,0,0) 51%);'}}></Box>
                  <Box sx={{width:30,height:30, background:'linear-gradient(135deg, rgb(229 229 229 / 37%) 0%, rgb(163 163 163 / 26%) 50%, rgba(0,0,0,0) 51%);'}}></Box>
                </Stack>                
                <Typography variant="h3" sx={{mb:3}}>
                It started making me money and more than paying for itself in a matter of hours. I can take stores that I want my Amazon store to be like and SSP will drop the building blocks directly into my lap.
                </Typography>
                <Typography variant="subtitle1">
                  chrisracic
                </Typography>
                <Typography variant="body1">
                Seasoned Amazon Seller
                </Typography>
              </CardContent>
            </Card>
            <Card sx={{ borderRadius:0 }}>
              <CardContent>
                <Stack direction="row" spacing={2} sx={{mb:2}}>
                <Box sx={{width:30,height:30, background:'linear-gradient(135deg, rgb(229 229 229 / 37%) 0%, rgb(163 163 163 / 26%) 50%, rgba(0,0,0,0) 51%);'}}></Box>
                <Box sx={{width:30,height:30, background:'linear-gradient(135deg, rgb(229 229 229 / 37%) 0%, rgb(163 163 163 / 26%) 50%, rgba(0,0,0,0) 51%);'}}></Box>
                </Stack>
                <Typography variant="h3" sx={{mb:3}}>
                I think what the SSP team has done here is created something that is going to blow its users out of the water.
                </Typography>
                <Typography variant="subtitle1" sx={{ mb: 0 }}>
                dereklee_oahunt
                </Typography>
                <Typography variant="body1">
                SSP User
                </Typography>
              </CardContent>
            </Card>         
          </Stack>
        </Box>

        <Box>
        <Typography variant="h3" sx={{ mb: 5 }} align="center">
            Features That Set Us Apart
          </Typography>
          <Grid container spacing={2}>
            <Grid xs={12} md={4}>
              <Card sx={{ 'height': '100%', borderRadius:0 }}>
                <CardContent>
                  <Typography variant="h6" sx={{ mb: 3 }}>
                    Built in Search
                  </Typography>
                  <Typography variant="body1">
                    With a single click you can search Google Shopping for the Title of the match on Amazon.</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={12} md={4}>
              <Card sx={{ 'height': '100%', borderRadius:0 }}>
                <CardContent>
                  <Typography variant="h6" sx={{ mb: 3 }}>
                    Built in Google Lens                  </Typography>
                  <Typography variant="body1">
                    With one click pull back image matches with Google Lens.</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={12} md={4}>
              <Card sx={{ 'height': '100%', borderRadius:0 }}>
                <CardContent>
                  <Typography variant="h6" sx={{ mb: 3 }}>
                    Historical Data                  </Typography>
                  <Typography variant="body1">
                    Quickly see the current, 30 day, and 90 day Buy Box Price.

                    Instantly know the 30 and 90 day Amazon in stock rate.</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={12} md={4}>
              <Card sx={{ 'height': '100%', borderRadius:0 }}>
                <CardContent>
                  <Typography variant="h6" sx={{ mb: 3 }}>
                    Powerful Filters                  </Typography>
                  <Typography variant="body1">
                    Filter your data any way you like.

                    Want to only see shoes that are priced above $50?

                    Maybe beauty under $15.

                    Our filters make that happen instantly.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={12} md={4}>
              <Card sx={{ 'height': '100%', borderRadius:0 }}>
                <CardContent>
                  <Typography variant="h6" sx={{ mb: 3 }}>
                    U.S. Based Support
                  </Typography>
                  <Typography variant="body1">
                    The founders are the ones who will support all your questions, bugs, and issues.

                    You can do this via email, chat, or the SSP Discord.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>

            <PricingView id="pricing" />

        <Box sx={{my: {xs:15, md:20} }}> 

        <Typography variant="h3" sx={{ mb: 5 }} align="center">
          Common Questions
        </Typography>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h4">
              What is Storefront Stalking?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
            Storefront stalking is the act of finding competing third party sellers on Amazon that seem to use the same model of selling that you do and researching their products to find the same or similar products to sell in your account.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography variant="h4">
              How does Storefront Stalker Pro help me?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
            Storefront Stalker Pro allows you to automate the process of reverse sourcing storefronts you want to stalk on a regular basis. You will be alerted every time new ASINs are added to the storefronts you're stalking.

Then the built in search features will help you find the best price for the leads you want to research.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography variant="h4">
              Is Storefront Stalker Pro right for me?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
            Storefront Stalker pro is not for everyone. You do need to have some understanding of how to source for products to sell on Amazon. Storefront Stalker Pro will help you find leads faster and stay on top of the stores you want to reverse source.
              </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography variant="h4">
              How do I find storefronts to stalk?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
            There are multiple methods. You can use Keepa to find products that are similar to what you want to sell on Amazon. You can then look through the stores that are already selling that or have sold it in the past. You can also find a winning product to sell on Amazon using other methods and then look for the other storefronts that may be selling that item. We also have some training on other methods to find storefronts inside our discord.
              </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography variant="h4">
              Do I need other tools to use Storefront Stalker Pro?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
            No. You should have a tool like Keepa as it has tons of information available to help you as a seller. You may also want a profit calculator tool (we suggest SellerAmp) but, none of these are required to access Storefront Stalker Pro.
              </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography variant="h4">
              How does the 7 day free trial work?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
            You get a 7-day free trial. No strings attached. You can cancel at any time before the free trial is over without any charge. If you decide to keep the software past the 7 days you will be charged your first month.
              </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography variant="h4">
              What kind of support do you offer?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
            You can contact us via email or in our private Discord community. You are also welcome to DM any of the team via Twitter.
              </Typography>
          </AccordionDetails>
        </Accordion>      

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography variant="h4">
              I have another question, how do I get in touch?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
            Feel free to email us at support@storefrontstalkerpro.com or reach out to Chrifs (one of the team) on Twitter here: https://twitter.com/cleartheshelf
              </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>

      <Box className="h-neonBox" sx={{ mx:{xs:5, lg:0}, my: {xs:20, md:30}, border:1, borderRadius:0, padding:3, boxShadow: '0 0 1px #fff, 0 0 5px #fff, 0 0 15px #fff, 0 0 4px #6d78b2, 0 0 26px #6d78b2, 0 0 39px #6d78b2, 0 0 56px #6d78b2, 0 0 1px #6d78b2'}}>
        <Typography variant="h3">
          Try the most advanced Storefront Stalker, free for 7 days
          </Typography>
        <Typography variant="body1" sx={{ mb: 5, maxWidth: '40rem' }}>
          Discover new products as soon as they're listed, and gain a competitive edge in your Amazon selling strategy.
        </Typography>
        <Stack direction="row" spacing={2}>
          <MuiButton variant="contained" color="brand">
          <Link href="#pricing" component={NextLink} variant="body2" sx={{color:'#ffffff'}}>
              Start 7-Day Free Trial
              </Link>
          </MuiButton>
        </Stack>
      </Box>

      </Container>


  
      
      

    </MainLayout>
  );
}